/*FONTS*/
@font-face {
    font-family: 'DejaVu Sans';
    src: url('../fonts/dejavu-sans-webfont.eot'); /* IE9 Compat Modes */
    src: url('../fonts/dejavu-sans-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/dejavu-sans-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/dejavu-sans-webfont.woff') format('woff'), /* Pretty Modern Browsers */
         url('../fonts/dejavu-sans-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/dejavu-sans-webfont.svg#dejavu_sansregular') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'DejaVu Sans Extra Light';
    src: url('../fonts/dejavu-sans-extra-light-webfont.eot'); /* IE9 Compat Modes */
    src: url('../fonts/dejavu-sans-extra-light-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/dejavu-sans-extra-light-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/dejavu-sans-extra-light-webfont.woff') format('woff'), /* Pretty Modern Browsers */
         url('../fonts/dejavu-sans-extra-light-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/dejavu-sans-extra-light-webfont.svg#dejavu_sans_extra_lightregular') format('svg'); /* Legacy iOS */
}

/*OVERALLS*/
:root {
  --dark-grey: #333;
  --light-grey: #666;
  --very-light-grey: #999;
  --yellow: #ffc800;
}
body{
  margin: 0;
  font-family: 'Roboto',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
               'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
	background-color:black;
	color: white;
  font-size:1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,h2,h3{
  margin:0;
  padding:0;
  font-family: 'DejaVu Sans',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
               'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
  font-size: 1.3em;
  user-select: none;
  font-weight: bold;
}
.link-button{
  font-size: 1.3em;
}
h2,h3{
  font-family: 'DejaVu Sans Extra Light',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
               'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
               font-size: 1.1em;
               font-weight: bold;
}
hr{
  margin-top:16px;
  margin-bottom:12px;
  padding:0;
}
a{
  text-decoration: none;
}
a>h1,a>h2,a.nav-logo-link{
  color:white;
}
a.nav-logo-link{
  user-select: none;
}
a.nav-logo-link:hover{
  color:white;
}
a.nav-logo-link:hover .nav-logo-rotate{
  transform: rotate(180deg);
}
a>h1:hover,a>h2:hover,.post-content-container a{
  text-decoration: underline;
}
.post-content-container a{
  font-weight: bold;
  color: var(--dark-grey);
}
.post-content-container a:hover{
  color:white;
}
.post-content-container p{
  margin-bottom:16px;
}
.post-content-container p:last-child{
  margin-bottom:0px;
}
.post-content-extra-text{
  position: relative;
  height:0px;
  overflow:hidden;
}
.post-content-extra-text.open{
  height:auto;
  margin-bottom: 16px;
}
.post-content-extra-link{
  user-select: none;
}
.post-title h3{
  user-select:text;
}
iframe,img,.lightbox-wrapper,.post-main-image{user-select: none;}
img{  display: block;}
.dark-grey-container {
    background-color: var(--dark-grey);
}
.light-grey-container {
    background-color: var(--light-grey);
}
.very-light-grey-container {
    background-color: var(--very-light-grey);
}
p{margin:0;}

/*SVG LOGO*/
.drop-cap-back {    fill:var(--dark-grey);}
.logo_highlight{fill:var(--yellow);}
.logo_main{fill:white;}
.drop-cap-back{fill:var(--dark-grey);}
.vertical-line.smaller{ height:19px;}
/* NAVBAR */
.nav-wrapper{
  margin-top:16px;
  border: 1px solid white;
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  background-color: var(--dark-grey);
  width:100%;
  position: relative;
}
.nav-bar{
  min-height:50px;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.nav-logo-link{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.nav-links{
  list-style: none;
  flex-direction: row;
  padding:0;
  margin:0;
}
.nav-links a{
  color: white;
  text-decoration: none;
  height:50px;
  display: flex;
  align-items: center;
}
.nav-links a.selected{
  background-color: white;
  color: black;
/*  pointer-events: none;*/
}
/*.category-page .nav-links a.selected{
  pointer-events: all;
}*/
.nav-links a:hover{
  background-color: var(--light-grey);
  color: white;
}
.mobile-menu-container {
  background-color: var(--dark-grey);
}
.nav-link-name{
  pointer-events: none;
  user-select: none;
}
.mobile-menu-container{
  height:0px;
  overflow: hidden;
  transition: 0.5s;
}
.mobile-menu-container.open{
  height:200px;
}
.nav-logo{
  width: 44px;
  height: 44px;
  margin-right:8px;
}
.nav-logo-rotate{
  width: 44px;
  height: 44px;
  padding: 0;
  margin: 0;
  transform: rotateZ(0deg);
  transition: .25s ease;
}
.nav-brand{
  margin-top:0px;
  font-size: 1em;
  margin-right:16px;
}
.nav-brand-bold{
  color: var(--yellow);
  font-weight: bold;
}
a:hover .nav-brand-not-bold{
  color: var(--yellow);
}
a:hover .nav-brand-bold{
  color: white;
}
.burger-container{
  flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}
.nav-container{
  padding-left:2px;
  padding-right:8px;
}

/* HOMEPAGE */

.body-container{
  padding-top: 0px;
    padding-bottom: 0px;
}
.home-body-video{
  width:100%;
  /*height:56.25vw;*/
  height:56.2vw;
}
.home-body-text{
  padding-left:16px;
  padding-top:16px;
  padding-bottom:8px;
}
.home-body-text h2{
  padding-bottom: 8px;
  user-select: text;
}
.home-main-image-container{
  width:100%;
  overflow: hidden;
}
/*.home-page a.nav-logo-link{
  pointer-events: none;
}*/

/*PAGE TITLE*/
.page-title-wrapper{
  padding-top: 16px;
    padding-bottom: 16px;
}
.page-title-wrapper.complex{
  padding-top: 16px;
    padding-bottom: 11px;
}
.drop-cap-container {
    width: 25px;
    height: 25px;
    top: 0px;
    position: relative;
    display: inline-block;
    margin-right: 16px;
}
.page-title-container{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
}
.page-title{
  display: flex;
  align-items: initial;
}
.page-title-page a{
  height:23px;
  top: 0px;
    position: relative;
}
.page-title-spacer {
  width: 25px;
    margin-left: 11px;
    margin-right: 1px;
    height: 25px;
    top: 0px;
    position: relative;
    display: block;
}
.page-title-project .page-title-spacer{
  height:19px;
  overflow: hidden;
}
.page-title-project h2{
  user-select: text;
}
.page-title-page-link{
  height:24px;
}
/* PAGE HEADER */

.page-header-wrapper{
  padding-top:16px;
  padding-bottom:16px;
}
.page-header .page-header-wrapper h2{
  color:white;
  margin-bottom: 8px;
  margin-top: 0px;
  line-height: 1.3;
  user-select: text;
}
.page-paragraph .page-header-wrapper h2{
  color:white;
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 0.9;
  user-select: text;
}
.page-header .page-header-wrapper h3, .page-paragraph .page-header-wrapper p{
  font-size: 0.8em;
  margin-bottom: 0px;
  line-height: 1.4;
  color: black;
  user-select: text;
}

/* PAGE PARAGRAPH (SEE ABOVE AS WELL) */

.page-paragraph .page-header-wrapper p{
  font-family: 'DejaVu Sans Extra Light',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
               'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
               font-weight: bold;
}

.page-paragraph-wrapper{
  text-align:center;
  padding-bottom: 0px;
}
.page-paragraph-images-wrapper{
  margin-top:16px;
}
.page-paragraph-image-container{
  overflow: hidden;
}


/* CARDS */
.card-content-image-container{
  overflow: hidden;
}
a.card-anchor img{
  transition: transform 0.5s;
}
a.card-anchor{
  width:100%;
  height:100%;
  display: block;
  text-decoration: none;
  color: white;
  user-select: none;
}
a.card-anchor:hover  {
  background-color: white;
  color: black;
}
a.card-anchor:hover img{
  transform: scale(1.1);
}
/*a.card-anchor:hover .side-line{
  background-color: var(--yellow);
}*/
.card-container {
  position: relative;
  pointer-events: none;
}
.card-container:before {
    content: '';
    position: absolute;
    top: 7px;
    right: 7px;
    border-right: 1px solid var(--yellow);
    border-top: 1px solid var(--yellow);
    height: 8px;
    width: 8px;
}
.card-container h2,.card-container h3{
  margin-top: -8px;
  margin-bottom: 10px;
}
.card-content{
  position:relative;
  pointer-events: none;
}
.side-line{
  width:1px;background-color: var(--yellow);height: 102%;position: absolute;top: 0px;left: -8px;
}
.horizontal-line{
  width:100%;background-color: var(--yellow);height: 1px;
}
.MuiGrid-grid-md-3 .side-line{
  height:105%;
}
.MuiGrid-grid-md-4 .side-line{
  height:103.5%;
}
.MuiGrid-grid-md-6 .side-line{
  height:102%;
}
.card-container h3{
  font-size: 1.1em;
}
/* POST PAGE */
.post-header-full{
  display: flex;
  flex-direction: column;
}
.post-header-no-video{
  display: flex;
  flex-direction: column;
}
.post-header-left{
  height:54.5vw;
  margin-bottom: 13px;
}
.post-header-left.portrait{
  height:177vw;
  margin-bottom: 13px;
}
.post-header-right{
  width:100%;
  display: flex;
  flex-direction: column;
}
.post-content-wrapper{
  padding-top: 13px;
  padding-bottom: 10px;
  color:var(--dark-grey);
}
.post-header-container{
  overflow: hidden;
}
.post-header .page-header-wrapper{
  padding-top: 0px;
    padding-bottom: 0px;
}
.post-header .page-header-wrapper h3{
  margin-top: -5px;
  color:white;
}
.post-main-image{
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  /*height:100px;*/
}
.post-main-image-overlay{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3);
  }
/*.post-main-image-absolute{
  position:absolute;
  width:100%;
}*/
.post-title{
  padding-left: 8px;
  margin-bottom: 11px;
}
.grid-list-wrapper{
  padding-top:0px;
  padding-bottom:0px;
}
/* LIGHTBOX */
.lightbox-wrapper{
  position: fixed;
  top: 0px;
  z-index: 100;
  background-color: rgba(0,0,0,0.8);
  width: 100%;
  height: 100%;
  display:none;
  align-items: center;
}
.lightbox-wrapper.show{
  display:flex;
}
.lightbox-wrapper.hide{
  display:none;
}
.lightbox-exit-container{
  position: absolute;
  top: 16px;
  right:16px;
  width:48px;
  height:48px;
      font-size: 3em;
  cursor: pointer;
}
/* FOOTER */
.footer-wrapper{
  border-top: 1px solid white;
    background-color: black;
    padding-top:14px;
    padding-bottom:11px;
}
.footer{
  text-align: center;
  font-size: 0.7em;
  color:white;
}
/* LOADERS */
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: white;
  font-size: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -1.5em;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

/* MOBILE SHOW / HIDE */
.mobile-show{  display:block;}
.mobile-show-flex{  display:flex;}
.mobile-show-inline{  display:inline-block;}
.mobile-hide,.mobile-hide-flex,.mobile-hide-inline{  display:none;}


@media (min-width: 600px){
  .link-button{
    font-size: 1.0em;
  }
  .page-header .page-header-wrapper h2{
    line-height: 0.8;
      margin-bottom: 12px;
  }
  .page-header .page-header-wrapper h3{
    line-height: 1.0;
  }
  .footer-wrapper{
      padding-top:28px;
      padding-bottom:24px;
  }
  .page-header-wrapper{
    padding-top:32px;
    padding-bottom:32px;
  }
  .page-paragraph-wrapper{
    padding-top:30px;
    padding-bottom:16px;
  }
  .page-header .page-header-wrapper h3,.page-paragraph .page-header-wrapper p{
    font-size: 0.8em;
  }

  .home-body-video{
    height:338px;
  }
  .footer{
    font-size:1.0em;
  }
  .grid-list-wrapper{
    padding-top:14px;
    padding-bottom:16px;
  }
  .post-content-wrapper{
    padding-top: 29px;
      padding-bottom: 24px;
  }
  .page-title-wrapper,.page-title-wrapper.complex{
    padding-top: 30px;
padding-bottom: 24px;
  }
  .nav-wrapper{
    margin-top:32px;
  }
  .drop-cap-container {
      width: 20px;
      height: 20px;
        top: 2px;
  }
  .page-title{
    display: flex;
    align-items: baseline;
  }
  .page-title-project .page-title-spacer{
    height:auto;
    overflow: visible;
  }
  .page-title-container{
      flex-direction: row;
  }
  .page-title-spacer {
      width: 2.2px;
      margin-left: 16px;
      margin-right: 14px;
      height: 25px;
      top: 1px;
  }
  .page-title-page a{
    height:29px;
  }
  .nav-container{
    padding-left:16px;
    padding-right:16px;
  }
  .post-title{
    padding-left: 0px;
    margin-bottom:16px;
  }
  .post-header .page-header-wrapper,.body-container{
    padding-top: 32px;
      padding-bottom: 32px;
  }
  .post-header-left{
    min-width:301px; /* + 64 */
    min-height:170px;/* + 36 */
    max-width:301px;
    max-height:170px;
    margin-right:16px;
    margin-bottom:0px;
  }
  .post-header-left.portrait{
    min-width:170px; /* + 64 */
    min-height:301px;/* + 36 */
    max-width:170px;
    max-height:301px;
    margin-right:16px;
    margin-bottom:0px;
  }
  .post-header-full{
    flex-direction: row;
    height:170px;
  }
  .post-header-full.portrait{
    flex-direction: row;
    height:301px;
  }
  .post-header-no-video{
    flex-direction: column;
    /* height:153px;*/
  }
  /*.post-main-image{
    height:100%;
  }*/
  .mobile-show,.mobile-show-flex,.mobile-show-inline{ display:none; }
  .mobile-hide{  display:block;}
  .mobile-hide-flex{  display:flex;}
  .mobile-hide-inline{  display:inline-block;}
}
@media (min-width: 960px){
  .link-button{
    font-size: 1.3em;
  }
  .page-header .page-header-wrapper h3,.page-paragraph .page-header-wrapper p{
    font-size: 0.85em;
  }
  .home-body-text h2{
    padding-bottom: 5px;
  }
  .home-body-text{
    padding-left:16px;
    padding-top:0px;
  }
  .home-body-content{
    padding-left:16px;
  }
  h1{
    font-size: 1.5em;
  }
  h2,h3{
    font-size: 1.3em;
  }
  .home-body-video{
    height:315px;
  }
  .card-container h3{
    font-size: 0.9em;
  }
  .post-header-no-video{
    flex-direction: column;
  /*  height:180px;*/
  }
  .post-header-full{
    height:216px;
  }
  .post-header-full.portrait{
    height:384px;
  }
  .post-header-left{
    /*min-width:320px;
    min-height:180px;*/
    min-width:384px; /* + 64 */
    min-height:216px;/* + 36 */
    max-width:384px;
    max-height:216px;
    margin-right:16px;
  }
  .post-header-left.portrait{
    /*min-width:320px;
    min-height:180px;*/
    min-width:216px; /* + 64 */
    min-height:384px;/* + 36 */
    max-width:216px;
    max-height:384px;
    margin-right:16px;
  }
  .page-title-wrapper,.page-title-wrapper.complex{
    padding-top: 27px;
    padding-bottom: 27px;
  }
}
@media (min-width: 1280px){
  .link-button{
    font-size: 1.5em;
  }
  .page-header .page-header-wrapper h2{
    margin-bottom: 16px;
  }
  .page-header .page-header-wrapper h3,.page-paragraph .page-header-wrapper p{
    font-size: 1.0em;
  }
  .home-body-text h2{
    padding-bottom: 8px;
  }
  .card-container h3{
    font-size: 1.1em;
  }
  .home-body-video{
    height:420px;
  }
  .page-title-page a {
    height: 38px;
  }
  .page-title-wrapper,.page-title-wrapper.complex{
    padding-top: 24px;
    padding-bottom: 25px;
  }
  .page-title-spacer {
    width: 3px;
  }
  h1{
    font-size: 2.0em;
  }
  h2,h3{
    font-size: 1.8em;
  }
  .drop-cap-container {
      width: 25px;
      height: 25px;
      top: 1px;
  }
}
